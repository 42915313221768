import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "trans-act",
      "style": {
        "position": "relative"
      }
    }}>{`Trans-Act`}<a parentName="h1" {...{
        "href": "#trans-act",
        "aria-label": "trans act permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <blockquote>
      <p parentName="blockquote">{`“A process cannot be understood by stopping it. Understanding must move with the flow of the process, must join it and flow with it.” ― Frank Herbert`}</p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/images/build/revolution.png",
            "alt": "The revolution will not be reported quarterly"
          }}></img></p>
        <Button to="https://dune.com/blog/revolution-not-quarterly" mdxType="Button">
    Read
        </Button>
      </Box>
    </InfoBlock>
    <p>{`In order to understand what `}<strong parentName="p">{`interdependent transfer mechanisms`}</strong>{` may look like, let's take a few steps backwards and begin with the tool that is most likely to be familiar to any data scientist: `}<a parentName="p" {...{
        "href": "https://dune.com/"
      }}>{`Dune`}</a>{`. The fact that so much financial data is already available in one place, which can be freely queried by anyone, and in which we can all build upon and learn from each other's queries and dashboards, is incredibly significant in and of itself. We must first understand this phase change before diving into deeper thoughts about what new and different kinds of tokens might mean for the sorts of facts we can agree upon and the kind of shared truths such values can finance. As the developers at Dune write:`}</p>
    <blockquote>
      <p parentName="blockquote">{`We’ve made access to this data easily readable and coupled it with a powerful analytics tool, all directly accessible for free on our website. This means that anyone can look at human readable data from the blockchain and turn it into dashboards with informative charts in a matter of minutes.`}</p>
    </blockquote>
    <h2 {...{
      "id": "open-plan",
      "style": {
        "position": "relative"
      }
    }}>{`Open Plan`}<a parentName="h2" {...{
        "href": "#open-plan",
        "aria-label": "open plan permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`We begin by clicking through Dune to look at various ways of finding good dashboards and interesting data. We will also consider how 'open' this open data really is.`}</li>
      <li parentName="ol">{`We then look in greater detail at `}<a parentName="li" {...{
          "href": "https://dune.com/gm365/gitcoin"
        }}>{`this Gitcoin dashboard`}</a>{`.`}</li>
      <li parentName="ol">{`We consider the data that is there, do some interpretation, and then consider what is missing.`}</li>
      <li parentName="ol">{`We craft a new query step by step, which should end up looking something like `}<a parentName="li" {...{
          "href": "https://dune.com/queries/1318905"
        }}>{`this`}</a>{`.`}</li>
      <li parentName="ol">{`We show how to submit your own contracts to be indexed by Dune and take `}<a parentName="li" {...{
          "href": "https://sign.kernel.community"
        }}>{`Signature Economies`}</a>{` as an example.`}</li>
      <li parentName="ol">{`We discuss `}<a parentName="li" {...{
          "href": "https://data.makerdao.network/"
        }}>{`data at MakerDAO`}</a>{`, it's differnt dynamics, the importance and representation and inventive interfaces, and the ethical requirements of working with open data.`}</li>
      <li parentName="ol">{`If you'd like to get involved in the wider discussion in the ecosystem, consider participating `}<a parentName="li" {...{
          "href": "https://metricsdao.notion.site/web3-Data-Glossary-750546b7d18447f39ac145f8e9532f4f"
        }}>{`here`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`In addition to the video below, you can also find the `}<a parentName="p" {...{
        "href": "https://docs.google.com/presentation/d/1alO_Xm25JxNRWXbEayHxmiBhFpLX_zUGwh-YyBYL0Gg/edit?usp=sharing"
      }}>{`slides here`}</a>{`.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/i2addcDHT68" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      